import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IResponse } from './rest.service.model';
import { map, catchError } from 'rxjs/operators';
import { AppConfiguration } from '../config/app-configuration';
import { AuthenticationService } from './authentication.service';
import { Subject } from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { AuthConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu'
@Injectable({ providedIn: 'root' }) 
export class RestService {
  private isRreloadAlerts = new Subject<boolean>();
  isIE:boolean;
  PreferredLanguage: string;
  authConfig: AuthConfig;
  public menuEmitter: EventEmitter<boolean>;
  isAlertChange :BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private oauthService: OAuthService,
    public httpClient: HttpClient, 
    private appConfig: AppConfiguration,
    private athenticationService: AuthenticationService,
    private locationStrategy: LocationStrategy,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private authStorage: OAuthStorage,
    ) 
  {
      this.isRreloadAlerts.next(false);
      this.menuEmitter = new EventEmitter<boolean>();
  }

  public get<T>(endpoint: string, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.baseUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json');
    return this.httpClient.get(`${baseUrl}${endpoint}`,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        if (res.ResponseCode === 200 || res.ResponseCode === 2000) {
          return res.Response as T;
        }
        return {} as T;
      }))
      .pipe(catchError(error => this.errorHandler(error)));
  }

  public post<T>(endpoint: string, request: any, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.baseUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json');
    return this.httpClient.post(`${baseUrl}${endpoint}`,
      request,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        if (res.ResponseCode === 200 || res.ResponseCode === 2000) {
          return res as any;
        }
        return res as any;
      }))
      .pipe(catchError(this.errorHandler));
  }

  public postGalielio<T>(endpoint: string, request: any, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    header = header.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient.post(`${endpoint}`,
      request,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: any) => {     
        return res as any;
      }))
      .pipe(catchError(this.errorHandler));
  }

  public freshDeskUrlPost<T>(endpoint: string, request: any, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.freshDeskUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json');
    return this.httpClient.post(`${baseUrl}${endpoint}`,
      request,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        return res as any;
      }))
      .pipe(catchError(this.errorHandler));
  }

  freshDeskUrlGet<T>(endpoint: string, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.freshDeskUrl;
    }
    header = header
    .set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json');
    return this.httpClient.get(`${baseUrl}${endpoint}`,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        return res as any;
      }))
      .pipe(catchError(error => this.errorHandler(error)));
  }

  private validateResponse(responseCode: number) {
    switch (responseCode) {
      case 4120:
        throwError('Required Field not present');
        break;
      case 4121:
        throwError('Field Validation Failed');
        break;
      case 4122:
        throwError('Business Rule Failed');
        break;
      case 5000:
        throwError('Unhandled Exception');
        break; 
    }
  }

// Need to handle error codes here
  private errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error);
  }


  public generateIDMLoginUrl(){
    let idmCoreServiceUrl = this.appConfig.idmCoreServiceUrl;
    let state = this.generateRandomString(16); // store in client side and once received token then validate the token for each request
    let nonce = this.generateRandomString(16);
    let usc = this.appConfig.usc;
    let redirect_uri = this.appConfig.redirect_uri;
    let ADM_url = `${idmCoreServiceUrl}/connect/authorize?response_type=token&client_id=${this.appConfig.appId['web']}&response_mode=fragment&scope=read%20write&state=${state}&nonce=${nonce}&usc=${usc}&redirect_uri=${redirect_uri}`
    this.athenticationService.setState(state);
    return ADM_url;
  }

  public generateIDMLogoutUrl(){
    let client_id = this.appConfig.appId['web'];
    let token = this.athenticationService.getToken();
    let ADM_logout_url = `${this.appConfig.idmCoreServiceUrl}/connect/endsession?post_logout_redirect_uri=${this.appConfig.redirect_uri}&client_id_hint=${client_id}&token_hint=${token}`
    return  ADM_logout_url;
  }
  
  public generateNewIDMLogoutUrl(){ 
    sessionStorage.removeItem("token");
    if(!this.authenticationService.getToken())
    {
      window.open(this.appConfig.websiteurl, "_self"); 
    }
    else
    {
      const target = localStorage.getItem('target');
      const state = localStorage.getItem('state');

      let client_id = this.appConfig.appId['web'];
      let token = this.athenticationService.getToken();
      let ADM_logout_url = `${this.appConfig.idmCoreServiceUrl}/connect/endsession?post_logout_redirect_uri=${this.appConfig.redirect_uri}&client_id_hint=${client_id}&token_hint=${token}`
      if(localStorage.getItem("DeviceID") && localStorage.getItem("DeviceID")!="")
      {
        const DeviceID = localStorage.getItem("DeviceID");
        const IsTrustedDevice = localStorage.getItem("IsTrustedDevice");
        localStorage.clear();
        localStorage.setItem("DeviceID", DeviceID);
        localStorage.setItem("IsTrustedDevice", IsTrustedDevice);
      }
      else
        localStorage.clear();
      if(target){ localStorage.setItem('target', target); }
      if(state){ localStorage.setItem('state', state); } 
      sessionStorage.removeItem('alerts');
      if( this.oauthService.issuer)
        this.oauthService.logOut();
      window.open(ADM_logout_url, "_self");
    }    
 }

  private  generateRandomString(StringLength){
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( var i = 0; i < StringLength; i++ ) { result += characters.charAt(Math.floor(Math.random() * charactersLength));}
    return result;
  }

  setReloadAlertsStatus(userLoggedIn: boolean) {
    this.isRreloadAlerts.next(userLoggedIn);
  }

  getReloadAlertsStatus(): Observable<boolean> {
    return this.isRreloadAlerts.asObservable(); 
  }

  preventBrowserBack(){
    history.pushState(null, null, window.location.href);  
    this.locationStrategy.onPopState((event) => {
      history.pushState(null, null, window.location.href); 
    });
  }

  isBrowserIE(){
    let match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
    this.isIE = false;
    if (match !== -1) { this.isIE = true; }
    return this.isIE;
  }

  parseQueryString(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  
  setDefaultLanguage(){
    var url = location.pathname;
    this.PreferredLanguage = this.parseQueryString('lang');
    if(!this.PreferredLanguage)
      this.PreferredLanguage = 'en';
    if (((url.includes('trouble-login')|| url.includes('hrtransactions') || url.includes('card-protection') || url.includes('download')|| url.includes('signupq') || url.includes('register')|| url.includes('enroll') || url.includes('cip') || url.includes('additional-verify'))) && this.PreferredLanguage)    
    {
      this.translate.setDefaultLang(this.PreferredLanguage);
      this.translate.use(this.PreferredLanguage);
    }
    else if(localStorage.getItem('data')){
      let data = JSON.parse(localStorage.getItem('data'));
      this.translate.setDefaultLang(data.Profile.PreferredLanguage);
      this.translate.use(data.Profile.PreferredLanguage);
      this.PreferredLanguage = data.Profile.PreferredLanguage;
    }
    else{
      this.translate.setDefaultLang('en');
      this.translate.use('en');
      this.PreferredLanguage = 'en';
    }
    //console.log('lang',this.PreferredLanguage);
  } 

  public postPreLogin<T>(endpoint: string, request: any, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.baseUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json')
    .set('isPreLogin','1');
    return this.httpClient.post(`${baseUrl}${endpoint}`,
      request,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        if (res.ResponseCode === 200 || res.ResponseCode === 2000) {
          return res as any;
        }
        return res as any;
      }))
      .pipe(catchError(this.errorHandler));
  }

  public getPreLogin<T>(endpoint: string, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.baseUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json')
    .set('isPreLogin','1');
    return this.httpClient.get(`${baseUrl}${endpoint}`,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        if (res.ResponseCode === 200 || res.ResponseCode === 2000) {
          return res.Response as T;
        }
        return {} as T;
      }))
      .pipe(catchError(error => this.errorHandler(error)));
  }

  openCardsMenu(): void {
    this.menuEmitter.emit(true);
  }
  closeCardsMenu(): void {
    this.menuEmitter.emit(false);
  }

}
