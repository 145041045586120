import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { IStep } from '@models/step-container.model';

@Directive()
@Injectable({ providedIn: 'root' })
export class StepperService {

  @Output() public stepChanged = new EventEmitter<IStep>();

  public steps: IStep[] = [];

  public currentStep: IStep;

  public mode: 'linear' |'conditional' = 'conditional';
  public ignoreExceptions = true;

  constructor() {}

  public setCurrentStep(step: IStep) {
    this.updateCurrentStep(step);
  }

  public setCurrentStepNo(stepNo: number) {
    this.findAndUpdateStep(stepNo);
  }

  public markStepCompleted(nextStep: number) {
    const nextStepNumber = this.mode === 'conditional' ? nextStep : this.currentStep.step + 1;
    this.findAndUpdateStep(nextStepNumber);
  }

  public markStepFailed(error: any, nextStep?: number, forceProceedOnIgnore?: number) {
    console.log(error);
    // setTimeout(() => {
      if (this.ignoreExceptions) {
        this.findAndUpdateStep(forceProceedOnIgnore);
      } else {
        const nextStepNumber = this.mode === 'conditional' ? nextStep : this.currentStep.step + 1;
        this.findAndUpdateStep(nextStepNumber);
      }
    // }
    // , 3000);
  }

  private findAndUpdateStep(nextStepNumber: number) {
    const filteredNextStep = this.steps.filter(f => f.step === nextStepNumber);
    if (filteredNextStep && filteredNextStep.length > 0) {
      const filteredStep = filteredNextStep[0];
      filteredStep.canActivate = true;
      this.updateCurrentStep(filteredStep);
    }
  }

  private updateCurrentStep(filteredStep: IStep) {
    this.currentStep = filteredStep;
    this.stepChanged.emit(this.currentStep);
  }

  public disbaleActivateFurtherSteps(step: IStep) {
    const disbableFutureSteps = this.steps.filter(f => f.step > step.step && !f.hide);
    if (disbableFutureSteps && disbableFutureSteps.length > 0) {
      disbableFutureSteps.forEach((s) => {
        s.canActivate = false;
      });
    }
  }

  public disbaleActivateFurtherStepsAfterThirdStep(step: IStep) {
    const disbableFutureSteps = this.steps.filter(f => f.step < step.step);
    if (disbableFutureSteps && disbableFutureSteps.length > 0) {
      disbableFutureSteps.forEach((s) => {
        s.canActivate = false;
      });
    }
  }
}
