<div class="verify-number" role="main">
    <div class="modal-header p-0 border-0">
        <button (click)="close()" type="button" class="btn-close" aria-label="Close">
            <i class="fa fa-close"></i>
        </button>
    </div>
   
    <h6 class="mb-10 mt-20"> {{'DASHBOARD.OLDWEBSITE.Header1' | translate }} </h6>
    <p class="mb-10"> {{'DASHBOARD.OLDWEBSITE.Header2' | translate }} </p>

    <div class="verify-input-main mb-10">
        <br>
        <div class="verify-input">          
            <textarea cdkFocusInitial id="code1"  #code1 class="form-input text-area-height"  rows="5"
                autofocus [(ngModel)]="feedback"
                [placeholder]="'DASHBOARD.OLDWEBSITE.Feedback' | translate">  </textarea>          
        </div>      
    </div>
    <div class="verify-number-btns mt-30">
        <button type="submit" (click)="SaveFeedBack()"  class = 'green-btn' >
            {{'DASHBOARD.OLDWEBSITE.Continue' | translate }} </button>      
    </div>
   
</div>