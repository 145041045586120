export class MessageUtils {

    public static getErrorMessage(error) {
        const messages = error.error.Messages;
        const message = messages.map(d => d.DisplayText).join('.');
        return message
    }

    public static getErrorMessageForSuccessResponse(response) {
        const messages = response.Messages;
        const message = messages.map(d => d.DisplayText).join('.');
        return message
    }
  }