export type CallbackFunction<T = any> = (event: T) => void;

export interface NewableType<T> {
  new(...args: any[]): T;
}

export abstract class Event {
  public readonly type: string;
}

export abstract class EventWithPayload<T> extends Event {
  public readonly payload: T;
  constructor(payload: T) {
    super();
    this.payload = payload;
  }
}
