import { Injectable } from '@angular/core';
import { MessageBusService } from './message-bus/message-bus.service';
import { DashboardService } from './../pages/dashboard/dashboard.service';
import { PostLoginResponseEvent } from './message-bus/message-bus-events';

@Injectable({providedIn:'root'})
export class GlobalService {

    constructor(public messageBus: MessageBusService, private dashboardService: DashboardService) { }

    lan: string;
    public cards;
    counter = 0;
  
    public getCardDesign(componentRef) {
      this.messageBus.on(PostLoginResponseEvent, (event) => {
        this.lan = event.payload.Profile.PreferredLanguage;
        this.cards = event.payload.Cards.filter(f => f.AccountTypeBit63 != 32);        
        if (this.cards && this.cards.length > 0)
          this.getDesign(componentRef);
      });
    }
  
    public getDesign(componentRef) {
      if (this.counter < this.cards.length) {       
        this.cards[this.counter].Balance = parseFloat(this.cards[this.counter].Balance).toFixed(2);
        if (this.cards[this.counter].AccountTypeName == "Nonshared")
        this.cards[this.counter].AccountTypeName = "Non-Shared";
        if (this.cards[this.counter].CardDisplayNumber.trim() == "")
        this.cards[this.counter].CardDisplayNumber = "xxxxxxxxxxxxXXXX";
  
        const cardPath = this.cards.find(f => f.CardDesignID === this.cards[this.counter].CardDesignID && f.ImagePath);
        if (cardPath) {
          localStorage.setItem('ImagePath', cardPath.ImagePath);
          this.cards[this.counter].ImagePath = cardPath.ImagePath;
          this.counter++;
          this.getDesign(componentRef);
        }
        else {
          this.dashboardService.getCardDesign(this.cards[this.counter].CardDesignID).subscribe(
            (data) => {

              localStorage.setItem('ImagePath', data[0].Type1ImageUri + "3x.png");
              this.cards[this.counter].ImagePath = data[0].Type1ImageUri + "3x.png";
              this.counter++;
              this.getDesign(componentRef);
            },
            (error) => { console.error(error); }
          );
        }
      }
    }
}
