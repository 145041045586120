<div class="input-group">
    <input type="password" class="form-control form-input-box" id= "{{id}}1" placeholder="{{placeholder}}" #input [(ngModel)]="value" [attr.required]=required 
    [attr.minlength]="minlength" [attr.maxlength]="maxlength"
    [ngClass]="{ 'is-invalid': isInvalid}" 
    (focusin)="focusin()">
    <div class="input-group-append">
        <span class="input-group-text background-color-light" #toggler>
            <i class="fa fa-eye"></i>
        </span>
    </div>
</div>