<container >
    <div class="body pt-30 p-30" *ngIf="isCodeValid">
        <div class="body width_100p" >
            <div class="container">
                <div class="row">
                    <div  class="success-msg p-30">
                        <h3> {{'DOWNLOADFILE.Success' | translate}} </h3>                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="body pt-30 p-30" *ngIf="!isCodeValid && errorMessage!=''">
        <div class="body width_100p" >
            <div class="container">
                <div class="row">
                    <div  class="step-error-msg p-30">
                        <h3> {{'QUICKREGISTER.Error' | translate}} </h3>
                        <p class="p-30"> {{errorMessage}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</container>
