import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfiguration } from '@config/app-configuration';

@Component({
  selector: 'smione-secure-redirect',
  templateUrl: './secure-redirect.component.html',
  styleUrls: ['./secure-redirect.component.scss']
})
export class SecureRedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private appConfig: AppConfiguration,
  ) { }

  ngOnInit(): void {
    //if((!localStorage.getItem('token') || localStorage.getItem('token')=="" || localStorage.getItem('token') =="null" ) && isNew && localStorage.getItem(ClientID))
    let ClientID = this.appConfig.appId['web'];
    ClientID  = "ls." + ClientID +  "identityPlatformAuthorizationData";
    if(localStorage.getItem(ClientID))
    {
      let tokenData = JSON.parse(localStorage.getItem(ClientID));
      if(tokenData && tokenData.token)
      {
        localStorage.setItem("token", tokenData.token );
        sessionStorage.setItem("token", tokenData.token );
        this.router.navigate([ '/login' ]);
      }
      else
        this.router.navigate(['/login']);
    }    
    else
        this.router.navigate(['/login']);
  }

}
