import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemeService } from '@services/theme.service';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'smione-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  isPrivatePolicy:boolean = false;
  componentName : string = 'PrivacyPolicy';
  constructor(
    public dialogRef: MatDialogRef<PrivacyPolicyComponent>,
    private themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.themeService.SetThemeInitially();
    if(this.dialogData['DisplayText'])
      this.isPrivatePolicy = true;
  }
  
  open(){ 
    this.analyticsService.gtag(this.componentName, `open`);
    this.dialogRef.close(this.dialogData['RedirectUrl']); 
  }

  close(){ 
    this.analyticsService.gtag(this.componentName, `close`);
    this.dialogRef.close(); 
  }

  
}
 