<div class="modal-content web-popp-content result-popup-width" role="main" >
        
    <div class="modal-header border-0 mb-15">
        <button (click)="close()" type="button" class="close p-0" data-dismiss="modal" aria-label="modalHeader">
            <i class="fa fa-close"></i>
        </button>
    </div>

    <div class="modal-body web-modal-popup mcb-icon">
        <span> <i class="fa fa-check success"></i> </span>
        <p [innerHTML]="dialogData?.message"></p>
    </div>
</div>