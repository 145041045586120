<div class="shell-container">
  <div>
      <img src="assets/images/shell/grey_circle_graphic.png" srcset="assets/images/shell/grey_circle_graphic@2x.png 2x" class="grey-circle-graphic" alt ="Graphic circle">
  </div>
  
  <div class="outer-container">
    <smione-smione-header></smione-smione-header>
      <router-outlet></router-outlet>
      <smione-smione-footer></smione-smione-footer>
  </div>
</div>