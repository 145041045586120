<!-- <div class="loading-screen-wrapper" style="display: block;">
  <div class="loading-screen-icon">
    <div class="blobs">
       <img src="assets/images/loader_logo.gif" class="blob-center"> 
      <img src="assets/images/loader_logo.gif" class="blob">
       <img src="assets/images/loader_logo.gif" class="blob">
      <img src="assets/images/loader_logo.gif" class="blob">
      <img src="assets/images/loader_logo.gif" class="blob">
      <img src="assets/images/loader_logo.gif" class="blob">
      <img src="assets/images/loader_logo.gif" class="blob"> 
    </div>
  </div>
</div> -->

<div class="loading-screen-wrapper" [hidden]="!isLoading">
  <div class="loading-screen-icon">
    <div class="blobs">
      <div class="blob">
        <img src="./assets/images/loader-img.gif" alt="Loading">
        <p> {{'LS.LOADING' | translate}} </p>
      </div>
    </div>
  </div>
</div>
<div class="loading-screen-wrapper" *ngIf="isLoadingWorker | async">
  <div class="loading-screen-icon">
    <div class="blobs">
      <div class="blob">
        <img src="./assets/images/loader-img.gif" alt="Loading">
        <p> {{'LS.LOADING' | translate}} </p>
      </div>
    </div>
  </div>
</div>