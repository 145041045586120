<section class="section-bg pt-30">
    <div class="container p-0">
        <div class="step-container web-registration step-container-top-0">
            <div class="quick-reg-header text-center">
                <img src="./assets/images/logo-white.png" alt="smiONE logo">
            </div>

            <ng-content select='.body'></ng-content>

            <div class="quick-sub-footer">

            </div>
            <div class="backcolor">
                <div *ngIf="!codeType">
                    <p> {{'DASHBOARD.FOOTER.Para1' | translate}} </p>
                    <div class="gap"> </div>
                    <p> {{'DASHBOARD.FOOTER.Para2' | translate}} </p>
                    <!-- <div class="gap"> </div> -->
                    <!-- <p> {{'DASHBOARD.FOOTER.Para3' | translate}} </p> -->
                </div>

                <div *ngIf="getActionTypeFromLocalStorage() != 'gen_fraud_secure_form'">
                    <div *ngIf="codeType">
                        <p *ngIf="codeType=='normal'"> {{'CIP.STEP1.Info1' | translate}}
                            <b> {{'CIP.STEP1.InfoBold' | translate}}</b>
                            {{'CIP.STEP1.Info2' | translate}}
                        </p>
                        <p *ngIf="codeType=='ae'"> {{'CIP.STEP1.InfoAE' | translate}}</p>
                        <div class="gap"> </div>
                        <p>
                            <b> {{'CIP.STEP2.Para2' | translate}}</b>
                            {{'CIP.STEP2.Para3' | translate}}
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="quick-footer">
        <img src="./assets/images/wave-module.png" width="100%" alt="Footer wave">
    </div>
</section>