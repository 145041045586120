<section class="nav" ngxHideOnScroll [classNameWhenHidden]="'nav--hidden'">
    <div *ngIf="showMyContainer" class="primary-date">
        <p>
            {{'DASHBOARD.HEADER.primaryHeader' | translate }}
            <button (click)="goToOldWebsite()" type="button"> {{'DASHBOARD.HEADER.goBack' | translate }} <i
                    class="fa fa-long-arrow-right"></i> </button>
        </p>
    </div>
    <div class="primary-header" *ngIf="IsNewWebsiteAvailable">
        <div class="container-fluid">
            <div class="toggle-part">
                <h6>
                    {{'DASHBOARD.HEADER.ActiveSite' | translate }}
                    <!-- <span class="toggle-icon" (click)="showMyContainer=!showMyContainer" [ngClass]="{'toggleIcon' : showMyContainer}">
                        <i class="fa fa-angle-down"></i>
                        <i class="fa fa-angle-up"></i>
                    </span> -->
                </h6>
                <div class="ph-btns">
                    <button type="button" (click)="goToOldWebsite($event)"> {{'DASHBOARD.HEADER.Old' | translate }}
                    </button>
                    <span> {{'DASHBOARD.HEADER.New' | translate }} </span>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row web-nav">
            <div class="col-md-4 nav-logo p-0">
                <div class="logo">
                    <a (click)="naviagteToDashboard()">
                        <img src="./assets/images/logo-main.png" width="" alt="smiONE logo">
                    </a>
                </div>

                <div class="btn-cards">
                    <button type="button" [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"
                        (click)="clickEvent()"
                        [ngClass]="cardsBtn ? 'btnopen custom-shipped-btn' : 'btnclose custom-shipped-btn'">
                        <span class="activecard-img">
                            <img [src]="selectedCard?.ImagePath" class="card-img" alt="card">

                            <!-- <img src="./assets/images/svg-icons/checkmark-img.svg" alt="checkmark" >  -->
                            <!-- <div *ngIf="selectedCard?.hasTracking" class="activecard-active">
                                    <span *ngIf="selectedCard?.shippingCurrentStep=='ordered'"><img
                                            src="./assets/images/svg-icons/O.svg" alt=""></span>
                                    <span *ngIf="selectedCard?.shippingCurrentStep=='shipped'"><img
                                            src="./assets/images/svg-icons/S.svg" alt=""></span>
                                    <span *ngIf="selectedCard?.shippingCurrentStep=='returned'" class="DI"><img
                                            src="./assets/images/svg-icons/ico-alert.svg" alt=""></span>
                                </div> -->

                            <div class="activecard-active">

                                <span class="lock"
                                    *ngIf="selectedCard?.StatusCode==='INA' && selectedCard?.SubStatusCode==='SUS'">
                                    <img src="./assets/images/svg-icons/ico - lock.svg" alt=""></span>

                                <span class="lost" *ngIf="(selectedCard?.StatusCode==='INA' && 
                                         selectedCard?.SubStatusCode==='LST' && selectedCard?.StatusCode==='STL')">
                                    <img src="./assets/images/svg-icons/ico refresh.svg" alt="">
                                </span>

                                <span class="lost" *ngIf="(selectedCard?.StatusCode==='INA' 
                                        && selectedCard?.SubStatusCode==='STL')">
                                    <img src="./assets/images/svg-icons/ico refresh.svg" alt="">
                                </span>

                                <span class="lost" *ngIf="(selectedCard?.StatusCode==='INA' && 
                                       selectedCard?.SubStatusCode==='LST')">
                                    <img src="./assets/images/svg-icons/ico refresh.svg" alt="">
                                </span>

                                <div *ngIf="shippingData?.isActivationReady">
                                    <span
                                        *ngIf="selectedCard?.SubStatusCode==='ACP' || selectedCard?.StatusCode==='ACP'">
                                        <img src="./assets/images/svg-icons/Check.svg" alt=""
                                            *ngIf="shippingData?.isActivationReady">
                                    </span>
                                </div>

                                <div *ngIf="!shippingData?.isActivationReady">
                                <span class="readytoactiveheadImg"
                                    *ngIf="selectedCard?.SubStatusCode==='ACP' || selectedCard?.StatusCode==='ACP'">
                                    <img class="readyactiveimg" src="./assets/images/svg-icons/Rectangle 21.svg" alt="" *ngIf="!shippingData?.isActivationReady">
                                </span>
                                </div>


                                <div *ngIf="selectedCard?.SubStatus==='ACTIVE' || selectedCard?.SubStatus==='ACTIVA'">
                                    <span *ngIf="selectedCard?.SubStatusCode!=='ACP' && selectedCard?.SubStatusCode!=='SUS' 
                                         && selectedCard?.SubStatusCode!=='LST'&& selectedCard?.SubStatusCode!=='STL'"> 
                                    <img src="./assets/images/svg-icons/Check.svg" alt=""
                                        *ngIf="selectedCard?.SubStatus==='ACTIVE' || selectedCard?.SubStatus==='ACTIVA'">
                                        </span>
                                </div>

                                <div *ngIf="selectedCard?.SubStatus!=='ACTIVE' && selectedCard?.SubStatus!=='ACTIVA'">  
                                <span class="lost" *ngIf="selectedCard?.SubStatusCode!=='ACP' && selectedCard?.SubStatusCode!=='SUS' 
                                         && selectedCard?.SubStatusCode!=='LST'&& selectedCard?.SubStatusCode!=='STL'">
                                    <img src="./assets/images/svg-icons/ico refresh.svg" alt=""
                                        *ngIf="selectedCard?.SubStatus!=='ACTIVE'"> 
                                </span>
                                </div>
                            </div>
                            <div *ngIf="selectedCard?.hasTracking == false" class="activecard-active">
                                <img src="./assets/images/svg-icons/checkmark-img.svg" alt="checkmark">
                            </div>


                        </span>
                        <span class="cardname font-mont">{{ selectedCard?.NickName }}</span>
                        <i class="fa fa-angle-up"></i>
                    </button>

                    <mat-menu #menu="matMenu" yPosition="below">
                        <div class="cardsMenu">
                            <span class="first-menu"> {{'DASHBOARD.HEADER.SwitchCardContext' | translate }} </span>
                            <div class="cards-main">
                                <div *ngFor="let item of cards"
                                    [ngClass]="item?.CardKey === selectedCard?.CardKey ? 'activeCard' : 'inactiveCard'"
                                    (click)="setCard(item)" class="cardslist activeCard">
                                    <span *ngIf="item?.CardKey === selectedCard?.CardKey" class="cardlist-icon "><img
                                            src="./assets/images/svg-icons/checkmark.svg" alt="checked"></span>
                                    <span class="cardlist-img"><img [src]="item?.ImagePath" class="card-img"
                                            alt="My card"></span>
                                    <span class="cardlist-name"> {{ item?.NickName }} </span>

                                </div>
                            </div>
                            <span *ngIf="selectedCard?.AllowOrderNewCard" class="last-menu" (click)="orderNewCard()"> <i
                                    class="fa fa-plus drop-icon"></i> {{'DASHBOARD.HEADER.AddNewCard' | translate }}
                            </span>
                        </div>
                    </mat-menu>
                </div>

                <div class="responsive-logo">
                    <a (click)="naviagteToDashboard()">
                        <img src="./assets/images/shell/smi-logo.png" width="" alt="smiONE logo">
                    </a>
                </div>
            </div>

            <div class="col-md-5 web-navigation p-0">
                <div class="navigation">

                    <ul (click)="preventCloseOnClick()" *ngIf="menuOpen" class="web-menu">
                        <li (click)="toggleMenu();">
                            <a [routerLink]="['/smione/dashboard']" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"> {{'DASHBOARD.HEADER.Overview' | translate }}
                            </a>
                        </li>
                        <li (click)="toggleMenu();">
                            <a [routerLink]="['/smione/card']" routerLinkActive="active" (click)="toggleField()">
                                {{'DASHBOARD.HEADER.activity' | translate}} </a>
                        </li>

                        <li (click)="toggleMenu();">
                            <a [routerLink]="['/smione/manage']" routerLinkActive="active" (click)="toggleField()">
                                {{'DASHBOARD.HEADER.Manage' | translate}} </a>
                        </li>

                        <li *ngIf="selectedCard?.AllowSavings && showSavings" (click)="toggleMenu();">
                            <a [routerLink]="['/smione/savings']" routerLinkActive="active" (click)="toggleField()"
                                [routerLinkActiveOptions]="{exact: true}"> {{'DASHBOARD.HEADER.MySavings' |
                                translate }} </a>
                        </li>
                        <li (click)="toggleMenu();">
                            <a [routerLink]="['/smione/support']" routerLinkActive="active" (click)="toggleField()"
                                [routerLinkActiveOptions]="{exact: true}" class="support-responsive">
                                {{'DASHBOARD.HEADER.Support' |
                                translate }} </a>
                        </li>
                        <li *ngIf="AllowPayPerks" (click)="toggleMenu();">
                            <a [routerLink]="['/smione/payperks']" routerLinkActive="active" (click)="toggleField()"
                                [routerLinkActiveOptions]="{exact: true}"> {{'DASHBOARD.HEADER.PayPerks' |
                                translate }} </a>
                        </li>
                        <li (click)="toggleMenu();">
                            <a [routerLink]="['/smione/settings']" routerLinkActive="active" (click)="toggleField()"
                                class="settings-responsive"> {{'DASHBOARD.HEADER.Settings' |
                                translate }} </a>
                        </li>
                        <li (click)="toggleMenu();">
                            <a (click)="logout()" id="logoutbtnres" #logoutbtnres class="logout-btn-responsive">
                                {{'DASHBOARD.HEADER.LogOut' | translate }} </a>
                        </li>
                    </ul>

                    <div class="web-box" (click)="toggleMenu(); preventCloseOnClick()">
                        <div class="menu-icon"></div>
                        <div class="menu-icon"></div>
                        <div class="menu-icon"></div>
                    </div>

                </div>
            </div>

            <div class="col-md-3 navi-profile p-0">
                <div class="nav-profile">
                    <ul class="nav-ul">
                        <li class="list-unstyled">
                            <div class="dropdown btn-notify">
                                <button class="btn np-bell dropdown-toggle" type="button" aria-label="notifications"
                                    data-toggle="dropdown">
                                    <i class="fa fa-bell"></i><span *ngIf="unreadCount">{{unreadCount}}</span>
                                </button>
                                <div class="dropdown-menu notify-menu-parent"
                                    [ngClass]="IsNewWebsiteAvailable?'primary-navigation-new':'web-banner'">
                                    <div class="notify-head"> {{'DASHBOARD.HEADER.Alerts' | translate }} </div>
                                    <!-- <pre>{{ alerts?.Items | json }}</pre> -->
                                    <ul class="notify-menu">
                                        <li *ngFor="let item of alerts?.Items | slice:0:5; let i=index">
                                            <span class="icon-menu">
                                                <i *ngIf="item?.AlertTypeCode !== 'WRN'" class="fa fa-bell"></i>
                                                <img *ngIf="item?.AlertTypeCode === 'WRN'"
                                                    src="./assets/images/support-icons/Rectangle-white.png" width="25px"
                                                    alt="Support">
                                            </span>

                                            <div class="notify-data-show">
                                                <h6> {{item?.CreatedDateTime | date :'MM/dd/yy'}} </h6>
                                                <a (click)="makeAlertRead()" *ngIf="item?.Content"
                                                    [innerHTML]="item?.Content.length > 130 ? (item?.Content | slice:0:130)+'.....' : item?.Content"></a>
                                            </div>
                                        </li>

                                        <li class="notify-more" (click)="makeAlertRead()">
                                            <a><i class="fa fa-ellipsis-h"></i>{{'DASHBOARD.HEADER.ViewMore' | translate
                                                }} </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>

                        <li class="list-unstyled">
                            <a [routerLink]="['/smione/support']" routerLinkActive="active" (click)="toggleField()"
                                [routerLinkActiveOptions]="{exact: true}" class="support-desktop"
                                aria-label="Show support">
                                <i class="fa fa-question"></i>
                            </a>
                        </li>

                        <li class="list-unstyled">
                            <a [routerLink]="['/smione/settings']" routerLinkActive="active" (click)="toggleField()"
                                class="settings-cog settings-desktop" aria-label="Show settings">
                                <i class="fa fa-cog"></i>
                            </a>
                        </li>

                        <li class="list-unstyled">
                            <a (click)="logout()" class="logout-btn"> {{'DASHBOARD.HEADER.LogOut' | translate }} </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>