<container *ngIf="isCodeValid">
    <div class="body width_100p">
        <div class="pt-30">

            <!-- <div class="quick-reg-header text-center">
                            <img src="./assets/images/logo-white.png">
                        </div> -->

            <div class="form-container web-registration-form m-auto">
                <div class="form-header"> {{'QUICKREGISTER.QuickRegistration' | translate}} </div>
                <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">

                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label" for="UserName">
                                {{'QUICKREGISTER.UserName' |
                                translate}}
                                <span class="required-field"> * </span>
                                <span class="custom-tooltip">
                                    <img class="margin-left-10" src="assets/images/Information.svg" alt ="Information">
                                    <span class="custom-tooltiptext"
                                        [innerHTML]="'QUICKREGISTER.UserNameToolTip' | translate"></span>
                                </span>

                            </label>
                        </div>

                        <div class="form-group col-8">
                            <input id= "UserName" [placeholder]="'QUICKREGISTER.UserName' | translate" autofocus type="text"
                                formControlName="username" class="form-control form-input-box form-input" (keyup)="chkPwdUn()"
                                [ngClass]="{ 'is-invalid': submmited && fval.username.errors }" minlength="7"
                                maxlength="15" required />
                            <div *ngIf="submmited && fval.username.errors" class="invalid-feedback">
                                <div *ngIf="fval.username.errors.required"> {{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                                <div *ngIf="fval.username.errors.minlength">
                                    {{'REGISTER.AS.UsernameMinlengthMsg' |
                                    translate}}
                                </div>
                                <div *ngIf="fval.username.errors.maxlength">
                                    {{'REGISTER.AS.UsernameMaxlengthMsg' |
                                    translate}}
                                </div>
                            </div>
                        </div>

                    </div>


                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label" for="password1">
                                {{'QUICKREGISTER.Password' |
                                translate}} <span class="required-field"> * </span></label>
                        </div>
                        <div class="form-group col-8">
                            <password-input id= "password" formControlName="password" (keyup)="chkPwdUn()"
                                [placeholder]="'QUICKREGISTER.Password' | translate" behaviour="press" [required]="true"
                                minlength=8 maxlength=20 (input)="clearIfInvalid($event)"
                                [ngClass]="{'is-invalid':(( submmited && fval.password?.errors)||(isPwdUn) ) }">
                            </password-input>
                            <password-strength-bar [passwordToCheck]="fval.password.value">
                            </password-strength-bar>

                            <div *ngIf="isPwdUn" class="invalid-feedback">
                                {{'QUICKREGISTER.PasswordNotUserName' |
                                translate}}</div>

                            <div *ngIf="submmited && fval.password.errors" class="invalid-feedback">
                                <div *ngIf="fval.password.errors.required"> {{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                                <div *ngIf="fval.password.errors.minlength">
                                    {{'REGISTER.AS.PasswordMinlengthMsg' |
                                    translate}}
                                </div>
                            </div>
                            
                            <mat-error id="error-message">"<span id="invalid-char"></span><span class="ml-1 d-inline-block">{{'DASHBOARD.SETTINGS.CP.CharacterIsNotAllowed' | translate }}</span>"</mat-error>

                            <p >
                                {{policyText}}
                            </p>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label">{{'QUICKREGISTER.RePassword' |
                                translate}} <span class="required-field"> *
                                </span></label>
                        </div>
                        <div class="form-group col-8">
                            <password-input [ngClass]="{ 'is-invalid': submmited && fval.confirmPassword.errors }"
                                maxlength=20 formControlName="confirmPassword"
                                [placeholder]="'QUICKREGISTER.RePassword' | translate" behaviour="press">
                            </password-input>
                            <div *ngIf="submmited && fval.confirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="fval.confirmPassword.errors.required">
                                    {{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                                <div *ngIf="fval.confirmPassword.errors.mustMatch">
                                    {{'REGISTER.AS.PasswordMustMatchMsg' |
                                    translate}} </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">


                    </div>
                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label">{{'QUICKREGISTER.Question' |
                                translate}} 1<span class="required-field"> *
                                </span></label>
                        </div>
                        <div class="form-group col-8">
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="question1" (selectionChange)='onQuestion1Change(t.value)'  #t
                                [ngClass]="{'is-invalid': fval?.question1?.errors && submmited }"  aria-label="matSelectQuestion">
                                    <mat-option disabled value=""> {{'QUICKREGISTER.SelectQuestion' | translate}} </mat-option>
                                    <mat-option [value]="i?.ID" *ngFor="let i of lstQuestion1"> {{i?.Question}} </mat-option>
                                </mat-select>
                                <mat-error class="" *ngIf="submmited && fval?.question1?.errors?.required"> {{'QUICKREGISTER.Required' | translate }} </mat-error>
                            </mat-form-field>
                            <!-- <select formControlName="question1" required #t class="form-control form-input"
                                [ngClass]="{'is-invalid': submmited && fval.question1.errors }"
                                (change)='onQuestion1Change(t.value)'>
                                <option disabled value="">{{'QUICKREGISTER.SelectQuestion' | translate }} 1
                                </option>
                                <option *ngFor="let i of lstQuestion1" [value]="i?.ID"> {{i?.Question}}
                                </option>
                            </select>
                            <div *ngIf="submmited && fval.question1.errors" class="invalid-feedback">
                                <div *ngIf="fval.question1.errors.required"> {{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label" for="answer1">
                                {{'QUICKREGISTER.Answer' |
                                translate}} 1<span class="required-field"> *
                                </span></label>
                        </div>
                        <div class="form-group col-8">
                            <input id= "answer1" formControlName="answer1" type="text" class="form-control form-input" maxlength=20
                                [ngClass]="{'is-invalid': submmited && fval.answer1.errors }">
                            <div *ngIf="submmited  && fval.answer1.errors" class="invalid-feedback">
                                <div *ngIf="fval.answer1.errors.required">{{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                                <div *ngIf="fval.answer1.errors.minlength">{{'QUICKREGISTER.SAMinLength' |
                                    translate}} </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label">{{'QUICKREGISTER.Question' |
                                translate}} 2<span class="required-field"> *
                                </span></label>
                        </div>
                        <div class="form-group col-8">
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="question2" (selectionChange)='onQuestion2Change(q.value)'  #q
                                [ngClass]="{'is-invalid': fval?.question2?.errors && submmited }" aria-label="matSelectQuestion2">
                                    <mat-option disabled value=""> {{'QUICKREGISTER.SelectQuestion' | translate}} </mat-option>
                                    <mat-option [value]="j?.ID" *ngFor="let j of lstQuestion2"> {{j?.Question}} </mat-option>
                                </mat-select>
                                <mat-error class="" *ngIf="submmited && fval?.question2?.errors?.required"> {{'QUICKREGISTER.Required' | translate }} </mat-error>
                            </mat-form-field>
                            <!-- <select formControlName="question2" required #q class="form-control form-input"
                                [ngClass]="{'is-invalid': submmited && fval.question1.errors }"
                                (change)='onQuestion2Change(q.value)'>
                                <option disabled value="">{{'QUICKREGISTER.SelectQuestion' | translate }} 2
                                </option>
                                <option *ngFor="let j of lstQuestion2" [value]="j?.ID"> {{j?.Question}}
                                </option>
                            </select>
                            <div *ngIf="submmited && fval.question2.errors" class="invalid-feedback">
                                <div *ngIf="fval.question2.errors.required"> {{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label" for="answer2">
                                {{'QUICKREGISTER.Answer' |
                                translate}} 2 <span class="required-field"> *
                                </span></label>
                        </div>
                        <div class="form-group col-8">
                            <input id= "answer2" formControlName="answer2" type="text" class="form-control form-input" maxlength=20
                                [ngClass]="{'is-invalid': submmited && fval.answer2.errors }">
                            <div *ngIf="submmited  && fval.answer2.errors" class="invalid-feedback">
                                <div *ngIf="fval.answer2.errors.required">{{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                                <div *ngIf="fval.answer2.errors.minlength">{{'QUICKREGISTER.SAMinLength' |
                                    translate}} </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-4">
                            <label class="form-label" for="cardno"> {{'QUICKREGISTER.Card'
                                |
                                translate}}<span class="required-field"> * </span>
                            </label>
                        </div>
                        <div class="form-group col-8">
                            <input id= "cardno" maxlength="4" formControlName="cardno" class="form-control form-input form-input-box width-120"
                                [ngClass]="{ 'is-invalid': submmited && fval.cardno.errors }" maxlength="4"
                                minlength="4" inputmode="numeric" pattern="[0-9]*" mask="0*" />
                            <div *ngIf="submmited && fval.cardno.errors" class="invalid-feedback">
                                <div *ngIf="fval.cardno.errors.required"> {{'QUICKREGISTER.Required' |
                                    translate}}
                                </div>
                                <div *ngIf="fval.cardno.errors.minlength"> {{'QUICKREGISTER.CardMinlength' |
                                    translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-12 text-center pt-2 mt-10 mb-20">
                            <button type="submit" class="mt-10 btn-padding"
                                [ngClass]="!form.invalid ? 'login-green-btn' : 'green-btnDisable'">{{'QUICKREGISTER.Submit'
                                | translate}}</button>
                            <button type="button" class="ca-cancel ml-20 mt-10 btn-padding"
                                (click)="resetForm()">{{'QUICKREGISTER.Cancel'
                                | translate}} </button>
                        </div>
                    </div>

                </form>
                <!-- <pre>{{ verifyForm.value | json }}</pre> -->
            </div>

        </div>
    </div>
</container>


<container *ngIf="!isCodeValid && errorMessage!=''">
    <div class="body width_100p">
        <div class="container">
            <div class="row">
                <div class="step-error-msg p-30">
                    <h3> {{'QUICKREGISTER.Error' | translate}} </h3>
                    <p class="p-30"> {{errorMessage}}</p>                   
                </div>
            </div>
        </div>
    </div>
</container>