import { Injectable } from '@angular/core'; 
import { AppConfiguration } from './../config/app-configuration';
declare var $;
export const themes = [
  {
    name: 'theme_1',
    themeColor: '#07c88f',
    colors: {
      'primary-color1': '#00C188',
      'primary-color2': '#00C582',
      'primary-bg-btn': 'linear-gradient(178deg, #20E1A8, #00C188)',
      'button-color': '#FFFFFF',
      'shadow-box': '#21c89733',
      'background-color': '#f1f5fd',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t1.svg\')',
    },
  },
  {
    name: 'theme_2',
    themeColor: '#825F87',
    colors: {
      'primary-color1': '#825F87',
      'primary-color2': '#825F87',
      'primary-bg-btn': 'linear-gradient(178deg, #a561af, #825f87)',
      'button-color': '#FFFFFF',
      'shadow-box': '#825f873b',
      'background-color': '#FFFF00',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t2.svg\')',
    },
  },
  {
    name: 'theme_3',
    themeColor: '#A36A01',
    colors: {
      'primary-color1': '#A36A01',
      'primary-color2': '#A36A01',
      'primary-bg-btn': 'linear-gradient(178deg, #bd8214, #a36a01)',
      'button-color': '#FFFFFF',
      'background-color': '#FF0000',
      'shadow-box': '#a36a0130',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t3.svg\')',
    },
  },
  {
    name: 'theme_4',
    themeColor: '#4CA100',
    colors: {
      'primary-color1': '#4CA100',
      'primary-color2': '#4CA100',
      'primary-bg-btn': 'linear-gradient(178deg, #6ecc1b, #4ca100)',
      'button-color': '#FFFFFF',
      'background-color': '#800000',
      'shadow-box': '#4ca10036',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t4.svg\')',
    },
  },
  {
    name: 'theme_5',
    themeColor: '#f3ca20',
    colors: {
      'primary-color1': '#f3ca20',
      'primary-color2': '#f3ca20',
      'primary-bg-btn': 'linear-gradient(178deg, #e8c329, #b19008)',
      'button-color': '#FFFFFF',
      'background-color': '#000000',
      'shadow-box': '#f3ca2026',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t4.svg\')',
    },
  },
  {
    name: 'theme_6',
    themeColor: '#3b4d61',
    colors: {
      'primary-color1': '#3b4d61',
      'primary-color2': '#3b4d61',
      'primary-bg-btn': 'linear-gradient(178deg, #395573, #3b4d61)',
      'button-color': '#FFFFFF',
      'background-color': '#6b7b8c',
      'shadow-box': '#3b4d6136',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t4.svg\')',
    },
  },
  {
    name: 'theme_7',
    themeColor: '#7a2048',
    colors: {
      'primary-color1': '#7a2048',
      'primary-color2': '#7a2048',
      'primary-bg-btn': 'linear-gradient(178deg, #a01d57, #7a2048)',
      'button-color': '#FFFFFF',
      'background-color': '#408ec6',
      'shadow-box': '#7a20482e',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t4.svg\')',
    },
  },
  {
    name: 'theme_8',
    themeColor: '#d2601a',
    colors: {
      'primary-color1': '#d2601a',
      'primary-color2': '#d2601a',
      'primary-bg-btn': 'linear-gradient(178deg, #e66313, #d2601a)',
      'button-color': '#FFFFFF',
      'background-color': '#fff1e1',
      'shadow-box': '#d2601a21',
      'logo-color': 'url(\'assets/shared-lib/icon-directory/t4.svg\')',
    },
  },

];

@Injectable({ providedIn: 'root' })
export class ThemeService {
  constructor(private appConfig: AppConfiguration) {}

    public getTheme(){
        const themeName = localStorage.getItem('theme');
        const theme = themes.find((x) => x.name === themeName);
        if(themeName && theme){
            return theme;
        }
        return;
    }

    public SetTheme(name) {
        const theme = themes.find((x) => x.name === name);
        if (!theme || !theme.colors) return;
        localStorage.setItem('theme', name);
        Object.keys(theme.colors).forEach((k) => {
            $('smione-app').css(`--${k}`, theme.colors[k]);
            $('smione-add-biller').css(`--${k}`, theme.colors[k]);
            $('smione-modify-biller').css(`--${k}`, theme.colors[k]);
            $('smione-find-atm-model').css(`--${k}`, theme.colors[k]);
            $('smione-warnings').css(`--${k}`, theme.colors[k]);
            $('smione-notification').css(`--${k}`, theme.colors[k]);
            $('smione-consent').css(`--${k}`, theme.colors[k]);
            $('smione-verify-mobile').css(`--${k}`, theme.colors[k]);
            $('smione-privacy-policy').css(`--${k}`, theme.colors[k]);
            $('smione-verify-code').css(`--${k}`, theme.colors[k]);
            $('smione-enter-code').css(`--${k}`, theme.colors[k]);
            $('confirmation-popup').css(`--${k}`, theme.colors[k]);
            $('smione-old-website').css(`--${k}`, theme.colors[k]);           
        });
    }

    public SetThemeInitially() {
      if(this.getTheme()){
        this.SetTheme(this.getTheme().name);
      }else{
        this.SetTheme(this.appConfig.theme);
      }
    }

}
