import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'smione-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {
  txtSuccess:string;
  componentName : string = 'SuccessModal';
  constructor(
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private analyticsService: AnalyticsService
  ) { 
    console.log(this.dialogData); 
    if(this.dialogData.isAutoClose)  
      setTimeout(()=>{ this.close(); },5000)    
  }

  ngOnInit() {}


  close(data?){
    this.analyticsService.gtag(this.componentName, `close`);
    this.dialogRef.close(data);
  }
}
