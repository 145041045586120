import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './common/material.module'; 
import { FormsModule } from '@angular/forms';
import { LoadingScreenComponent } from '@common/loading-screen/loading-screen.component';
import { AppConfiguration } from './config/app-configuration';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { JsonAppConfigService } from '@config/json-app-config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { CardProtectionComponent } from './common/card-protection/card-protection.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxMaskModule } from 'ngx-mask';
import { PrivacyPolicyComponent } from '@common/privacy-policy/privacy-policy.component';
import { WarningsComponent } from '@common/warnings/warnings.component';
import { ConsentComponent } from '@common/consent/consent.component';
import { VerifyMobileComponent } from '@common/verify-mobile/verify-mobile.component';
import { LoginSharedModule } from '@login-common/common.module';
import { DownloadAppComponent } from '@common/download-app/download-app.component';
import { RedirectComponent } from '@common/redirect/redirect.component';
import { QuickRegistrationComponent } from './pages/login/quick-registration/quick-registration.component';
import { DownloadFileComponent } from '@common/download-file/download-file.component';
import { ErrorPageComponent } from '@common/error-page/error-page.component';
import { OldWebsiteComponent } from '@common/old-website/old-website.component';
import { SecureRedirectComponent } from './common/secure-redirect/secure-redirect.component';
import { HighRiskComponent } from './common/high-risk/high-risk.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AdditionalVerificationComponent } from './pages/login/additional-verification/additional-verification.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


export function initializerFn(jsonAppConfigService: JsonAppConfigService) {
  return () => {
    return jsonAppConfigService.load();
  };
}
@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,
    CardProtectionComponent,
    PrivacyPolicyComponent,
    WarningsComponent,
    ConsentComponent,
    VerifyMobileComponent,
    OldWebsiteComponent,
    DownloadAppComponent,
    ErrorPageComponent,
    RedirectComponent,
    QuickRegistrationComponent,
    DownloadFileComponent,
    SecureRedirectComponent,
    HighRiskComponent,
    AdditionalVerificationComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoginSharedModule,
    SlickCarouselModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
      }
    ),
    NgIdleKeepaliveModule.forRoot(),
    NgxMaskModule.forRoot(),
    OAuthModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(initializerFn.bind(this)),
    NgxGoogleAnalyticsRouterModule,
    NgxMaskModule.forChild(),
    BsDatepickerModule.forRoot()
  ],
  providers: [
    { provide: AppConfiguration, deps: [HttpClient], useExisting: JsonAppConfigService },
    { provide: APP_INITIALIZER, multi: true, deps: [JsonAppConfigService], useFactory: initializerFn },
    TranslateModule,
    DatePipe
  ],
  entryComponents:[
    PrivacyPolicyComponent,
    WarningsComponent,
    ConsentComponent,
    VerifyMobileComponent,
    OldWebsiteComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
