<section class="web-only-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="woc-para">
                    <p> {{'DASHBOARD.FOOTER.Para1' | translate}} </p>
                    <div class="gap"> </div>  
                    <p> {{'DASHBOARD.FOOTER.Para2' | translate}} </p>
                    <!-- <div class="gap"> </div>   -->
                    <!-- <p> {{'DASHBOARD.FOOTER.Para3' | translate}} </p> -->
                </div>
            </div>
        </div>
    </div>
</section>


<footer role="group">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="footer-part footer-r-b">

                    <div class="footer-logo">
                        <h3>
                            <img src="./assets/images/logo-white.png" alt="Footer logo">
                        </h3>
                    </div>

                    <div class="dwnld-links">
                        <span>
                            <a href="https://itunes.apple.com/us/app/platinum-smione-visa-prepaid/id1147476680?mt=8" target="_blank" title="app-ios" aria-label="Link on Play Store">
                                <img src="./assets/images/app-ios.png"  alt="Get it on Play Store">
                            </a>
                        </span>

                        <span>
                            <a href="https://play.google.com/store/apps/details?id=com.smi.m360.cardholder&hl=en_US" target="_blank" title="app-android" aria-label="Link on Google Play"> 
                                <img src="./assets/images/app-android.png" alt="Get it on Google Play">
                            </a>
                        </span>
                    </div>

                </div>
            </div>

            <div class="col-md-4 col-sm-4">
                <div class="footer-part">
                    <ul>
                        <li> <a (click)="redirectTo('smione/dashboard/cardholder-agreement')"> {{'DASHBOARD.FOOTER.CardholderAgreement' | translate}} </a> </li>
                        <li *ngIf="showCards"> <a (click)="redirectTo('smione/dashboard/saving-cardholder-agreement')"> {{'DASHBOARD.FOOTER.SavingCardholderAgreement' | translate}} </a> </li>
                        <li *ngIf="showCards"> <a (click)="redirectToSavingAccount('smione/dashboard/saving-esign-account')"> {{'DASHBOARD.FOOTER.SavingCardholderAccount' | translate}} </a> </li>
                        <li> <a (click)="opensmi()"> {{'DASHBOARD.FOOTER.AboutUs' | translate}} </a> </li>
                        <li> <a (click)="openPrivacyPolicy()">{{'DASHBOARD.FOOTER.PrivacyPolicy' | translate}} </a> </li>
                        <li> <a (click)="redirectTo('smione/dashboard/terms-of-use')"> {{'DASHBOARD.FOOTER.TermsOfUse' | translate}} </a> </li>
                        <li> <a (click)="redirectTo('smione/dashboard/esign-agreement')"> {{'DASHBOARD.FOOTER.ESignAgreement' | translate}} </a> </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4 col-sm-4">
                <div class="footer-part footer-copyrights">
                    <p>
                        {{'DASHBOARD.FOOTER.DownloadTheApp' | translate}} <br/>
                        {{'DASHBOARD.FOOTER.Text' | translate}} <strong> {{'DASHBOARD.FOOTER.App' | translate}} </strong> {{'DASHBOARD.FOOTER.To' | translate}}  <strong> {{'DASHBOARD.FOOTER.42627' | translate}} </strong> &nbsp; {{'DASHBOARD.FOOTER.Reply' | translate}}  <br/> {{'DASHBOARD.FOOTER.Charges' | translate}} 
                    </p>
                    <span>
                        {{'DASHBOARD.FOOTER.Copright2020SIMOne' | translate}} <br/> {{'DASHBOARD.FOOTER.AllRightsReserved' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</footer>