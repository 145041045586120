import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'smione-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    localStorage.removeItem("target");
    if(this.router.url == '/deposit'){
      this.router.navigate(['smione','manage'], { queryParams: { t: 1 } });
    }else if(this.router.url == '/fees'){
      this.router.navigate(['smione','card'], { queryParams: { t: 4 } });
    }else if(this.router.url == '/alert'){   
      this.router.navigate(['smione','dashboard','notification']);
    }else if(this.router.url == '/setting'){    
      this.router.navigate(['smione','settings'], { queryParams: { t: 1 } });
    }
  }
}
