import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { LoadingScreenService } from './loading-screen.service';
import { finalize } from "rxjs/operators";
import { MessageUtils } from "@utils/message.utils";
import { RestService } from './rest.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorModalComponent } from '@common/error-modal/error-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SmiOneHttpInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private loadingService: LoadingScreenService,
    private restService: RestService,
    private dialog: MatDialog,
    private translate: TranslateService,
    ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokens = this.authenticationService.getToken();
    const  preLoginToken =  sessionStorage.getItem("pre-login-token");
    request = request.clone({setHeaders: {'Cache-Control': 'no-cache', Pragma: 'no-cache','Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'}}); 
    
    if (request.headers.getAll("isPreLogin") && preLoginToken)
    {
      request = request.clone({setHeaders: { 'Authorization':'Bearer ' + preLoginToken}});        
    }
    else if (request.headers.getAll("isPreLogin") ||  request.url.includes('app.config.json') ||  request.url.includes('assets'))
    {
            
    }  
   else if (tokens) {
      if(!sessionStorage.getItem("token") || (sessionStorage.getItem("token") == "null"))
      {
        localStorage.removeItem("token");
        this.restService.generateNewIDMLogoutUrl();
      }
      else if (tokens != sessionStorage.getItem("token")) 
      {
        localStorage.removeItem("token");
        this.restService.generateNewIDMLogoutUrl(); 
      }
      else
        request = request.clone({setHeaders: {'Authorization':'Bearer ' + tokens}});        
    }
    if(localStorage.getItem("DeviceID")&& localStorage.getItem("DeviceID")!="")
    {
      request = request.clone({setHeaders: {'Device': localStorage.getItem("DeviceID")}});
    }
    if ( request.url.includes('freshdesk')) {      
      request = request.clone({ headers: request.headers.set('Authorization', 'Basic ' +  btoa(localStorage.getItem('FreshDeskAPIKey')))});    
      
    }

    // if ( !request.url.includes('alerts?CardKey=')) {
      this.requests.push(request);
      this.loadingService.isLoading.next(true);
    // }

    return next
      .handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) { 
            // console.log('[Api Response]=>', event); 
          }
          return event;
        }),
        catchError((response) => {        
          if (response instanceof HttpErrorResponse && (!response.url.includes('quick-register/access')) && (!response.url.includes('transactions/high-risk/'))&& (!response.url.includes('download/document')) && (!response.url.includes('cip/access')) && (!response.url.includes('card/pinchange/commit' ))) {
            console.error('[Api Error Response]=>', response);            
              if (response.status === 401) {
                this.restService.generateNewIDMLogoutUrl();              
              }
              else{
                if (response.error && response.error.Messages) {
                  let message = '';
                  if(!response.url.includes('freshdesk.com')){
                    if(response.url.includes('dda/pdf/download') || response.url.includes('statements/download')){
                      this.translate.get('DASHBOARD.MYCARD.UnableToProcessTheRequest').subscribe(translatedValue =>{ message = translatedValue; });
                    }else{
                      message = MessageUtils.getErrorMessage(response);
                    }
                    if(!response.url.includes('transfer-funds') && !response.url.includes('accounts/create') && !response.url.includes('reset-password') && !response.url.includes('validate-ssn') && !response.url.includes('ssn/validate')){
                      this.showPopup(message);
                    }
                  }
                }else{ 
                  let message = response.statusText;
                  this.showPopup(message);
                }
              }
          }
          return throwError(response);
        }),

        finalize(() => {
          // if ( !request.url.includes('alerts?CardKey=')) {
            this.removeRequest(request);
          // }
        })
      );
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) { this.requests.splice(i, 1); }
    this.loadingService.isLoading.next(this.requests.length > 0);
  }

  showPopup(data){
    this.dialog.open(ErrorModalComponent, {
      disableClose : true,
      data : { message : data } 
    });
  }

}
