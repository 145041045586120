import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smione-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.scss']
})
export class DownloadAppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.getMobileOperatingSystem();
  }

  getMobileOperatingSystem() {
    if (navigator.userAgent.match(/Android/)) {
      window.open('https://play.google.com/store/apps/details?id=com.smi.m360.cardholder&hl=en_US');
    } else if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {
      window.open('https://itunes.apple.com/us/app/platinum-smione-visa-prepaid/id1147476680?mt=8');
    }
  }

}
