import { Component, AfterViewInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {
};

@Component({
  selector: 'password-input',
  templateUrl: 'password-input.component.html',
  styleUrls: ['../common.styles.scss', 'password-input.component.scss'],
  providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: PasswordInputComponent }
  ]
})
export class PasswordInputComponent implements AfterViewInit, ControlValueAccessor {

  @Output() propertyChanged = new EventEmitter<string>();

  private _value: string;
  private _onChange: (_: any) => void = noop;

  @ViewChild('input') el: ElementRef;
  @ViewChild('toggler') toggler: ElementRef;
  @Input() placeholder: string;
  @Input() isInvalid: boolean;
  @Input() behaviour = 'press';
  @Input() required = false;
  @Input() minlength = 100;
  @Input() maxlength = 200;
  @Input() id : string;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this._onChange(v);
    }
  }

  ngAfterViewInit() {
    const __this = this;
    const textbox = __this.el.nativeElement;
    const toggler = __this.toggler.nativeElement;
    const togglerIcon = toggler.childNodes[0];

    if (__this.behaviour === 'press') {
      toggler.addEventListener('mousedown', (e) => {
        textbox.type = 'text';
        togglerIcon.classList.remove('fa-eye-slash');
        togglerIcon.classList.add('fa-eye');
      });
      toggler.addEventListener('touchstart', (e) => {
        textbox.type = 'text';
        togglerIcon.classList.remove('fa-eye-slash');
        togglerIcon.classList.add('fa-eye');
      });
      toggler.addEventListener('mouseup', (e) => {
        textbox.type = 'password';
        togglerIcon.classList.remove('fa-eye');
        togglerIcon.classList.add('fa-eye-slash');
      });
    }

    if (__this.behaviour === 'click') {
      toggler.addEventListener('click', (e) => {
        textbox.type = textbox.type === 'password' ? 'text' : 'password';
        togglerIcon.classList.toggle('fa-eye');
        togglerIcon.classList.toggle('fa-eye-slash');
      });
    }
  }

  writeValue(value: any) {
    this._value = value;
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {}

  public focusin() {
    this.propertyChanged.emit('');
  }

}
