import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { RestService } from '@services/rest.service';
import { MessageUtils } from '@utils/message.utils';
import { DownloadFileService } from './download-file.service';

@Component({
  selector: 'smione-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit {
  PreferredLanguage:string;
  code:string;
  fileName:string;
  isCodeValid:boolean = false;
  errorMessage:string = "";
  constructor(  private dfService:DownloadFileService, 
                private restService: RestService,
                private translate: TranslateService,
                private sanitizer: DomSanitizer,) {
    this.restService.setDefaultLanguage();
   }

  ngOnInit() {
    this.PreferredLanguage = this.parseQueryString('lang');
    this.code = this.parseQueryString('id'); 
   
    if(this.code && this.code.toString().substr(this.code.toString().length - 2)=="th")
      this.fileName = "Transaction_history.pdf";
    else
      this.fileName="DDAForm.pdf";

    this.downloadFile();
  }
  parseQueryString(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
  downloadFile()
  {
    this.dfService.downloadFile(this.code).subscribe(
      (res) =>{     
        if(res)
        {              
          this.isCodeValid = true;    
          const blob = new Blob([res]);
          
          // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          //   console.log("in window.navigator");
          //   window.navigator.msSaveBlob(blob, this.fileName);
          // } else 
          {
            const fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = this.fileName;
            a.click();
            URL.revokeObjectURL(objectUrl);
          }
        }  
        else
        {    
          let message = MessageUtils.getErrorMessageForSuccessResponse(res);
          console.log(message);
          this.errorMessage= message;  
        }         
      },
      error => {  
        const messages = error.error.Messages;  
        var message:string="";
        if (this.restService.isBrowserIE())  
          message = "Document link is invalid or has expired. Please call the number on the back of your card to request another link.";       
        else        
          message = messages.map(d => d.DisplayText).join('.');      
        this.errorMessage= message;            
      }); 
  }
}
