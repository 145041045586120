import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { IResponse } from './rest.service.model';

import { map, catchError } from 'rxjs/operators';
import { AppConfiguration } from '../config/app-configuration';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' }) 
export class IdmRestService {

  constructor(
    public httpClient: HttpClient, 
    private appConfig: AppConfiguration,
    private athenticationService: AuthenticationService
    ) {
  }
  public get<T>(endpoint: string, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.idmServiceUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json');
    return this.httpClient.get(`${baseUrl}${endpoint}`,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        if (res.ResponseCode === 200 || res.ResponseCode === 2000) {
          return res.Response as T;
        }
        return {} as T;
      }))
      .pipe(catchError(error => this.errorHandler(error)));
  }
  public post<T>(endpoint: string, request: any, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.idmServiceUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json');
    return this.httpClient.post(`${baseUrl}${endpoint}`,
      request,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        if (res.ResponseCode === 200 || res.ResponseCode === 2000) {
          return res as any;
        }
        return res as any;
      }))
      .pipe(catchError(this.errorHandler));
  }

  private validateResponse(responseCode: number) {
    switch (responseCode) {
      case 4120:
        throwError('Required Field not present');
        break;
      case 4121:
        throwError('Field Validation Failed');
        break;
      case 4122:
        throwError('Business Rule Failed');
        break;
      case 5000:
        throwError('Unhandled Exception');
        break; 
    }
  }

  private errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error);
  }

  public getPreLogin<T>(endpoint: string, baseUrl?: string,
    params: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders()): Observable<T> {
    if (!baseUrl) {
      baseUrl = this.appConfig.idmServiceUrl;
    }
    header = header.set('Content-Type', 'application/x-www-form-urlencoded')
    .set('Content-Type', 'application/json')
    .set('isPreLogin','1');
    return this.httpClient.get(`${baseUrl}${endpoint}`,
      {
        headers: header,
        params: params
      })
      .pipe(map((res: IResponse<T>) => {
        this.validateResponse(res.ResponseCode);
        if (res.ResponseCode === 200 || res.ResponseCode === 2000) {
          return res.Response as T;
        }
        return {} as T;
      }))
      .pipe(catchError(error => this.errorHandler(error)));
  }
}
