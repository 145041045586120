import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';

@NgModule({
  imports: [ 
        CommonModule, 
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: ChildHttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [ TranslateModule ],
    providers:[ TranslateService ]
})
export class TranslateChildModule { }

export function ChildHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
