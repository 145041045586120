import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '@services/rest.service';
import { IdmRestService } from '@services/idmrest.service';
import { AppConfiguration } from '@config/app-configuration';


@Injectable({ providedIn: 'root' })
export class QuickRegistrationService {
    constructor(
        private restService: RestService,
        private idmRestService: IdmRestService,
        private appConfig: AppConfiguration,
    ) { }

    verifyLink(code:string): Observable<any> {  
        let url = `/api/cardholders/quick-register/access?code=${code}`;     
        let ret = this.restService.getPreLogin<any>(url, null, null);       
        return ret;
      }

    getPasswordPolicy(language:string, applicationID: string): Observable<any> {       
        let url = `/api/application/password-policy?applicationID=${applicationID}&isnew=true&language=${language}`;
        let ret = this.idmRestService.getPreLogin<any>(url, null, null);       
        return ret;
      }

      quickRegister(objRegister): Observable<any> {         
        return this.restService.postPreLogin<any>('/api/cardholders/cards/quick-register', objRegister);
      }

      getSecurityQuestion(language:string): Observable<any> {
        let sourceApplicationId = this.appConfig.appId['web']; 
        const httpParams = new HttpParams() 
        .set('language', language)
        .set('sourceApplicationId', sourceApplicationId)   ;       
        let url = "/api/masters/security-questions";
        return this.idmRestService.getPreLogin<any>(url, null, httpParams);
      }
}

