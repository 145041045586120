import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from './../../../pages/dashboard/dashboard.service';
import { MessageBusService } from '@services/message-bus/message-bus.service';
import { Card } from '@models/post-login.model';
import { PostLoginResponseEvent, SelectedCardEvent } from '@services/message-bus/message-bus-events';
import { PrivacyPolicyComponent } from '@common/privacy-policy/privacy-policy.component';
import { AnalyticsService } from '@services/analytics.service';
@Component({
  selector: 'smione-smione-footer',
  templateUrl: './smione-footer.component.html',
  styleUrls: ['./smione-footer.component.scss']
})
export class SmioneFooterComponent implements OnInit {
  public selectedCard: Card = {} as Card;
  data :any;
  public cards;
  showCards: boolean = false;
  public savingCards:Card[];
  public savingCard:Card;
  componentName : string = 'SmioneFooter';
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private dashboardService: DashboardService,
    public messageBus: MessageBusService,
    private analyticsService: AnalyticsService
  ) 
  {       
    this.messageBus.on(PostLoginResponseEvent, (event) => { 
     this.cards = event.payload.Cards;     
    });

    this.messageBus.on(SelectedCardEvent, (event) => 
    {
      this.selectedCard = event.payload; 
      if(this.selectedCard.AllowSavings)
      {
        if(this.selectedCard.AccountTypeBit63 === 32)
        {
          this.savingCard = this.selectedCard;
        }
        else
        {
          const savingCards = this.cards.filter(f => f.AccountTypeBit63 === 32);
          if(savingCards && savingCards.length){ 
            this.savingCards = savingCards;
            const currentSavingCard = savingCards.find(f=> f.ParentCardKey === this.selectedCard.CardKey);
            if(currentSavingCard){ 
              this.savingCard = currentSavingCard;             
            }
          }
          if (this.savingCard)
          {
            this.showCards = true;
            localStorage.setItem("savingCard", this.savingCard.CardKey.toString());
          }        
        }
      }
      else
      {
        this.showCards = false;
      }
     });


   }

  ngOnInit() {
    $(document).on("contextmenu",  function() {        
      //return false;
    });
  }

  redirectTo(url){ 
    this.analyticsService.gtag(this.componentName, `redirectTo-${url}`);
    this.router.navigate([url]);
  }

  redirectToSavingAccount(url){
    this.analyticsService.gtag(this.componentName, `redirectToSavingAccount-${url}`);
    localStorage.setItem("IsAccount", "1");
    this.router.navigate([url]);
  }

  openPrivacyPolicy(){
    this.analyticsService.gtag(this.componentName, `openPrivacyPolicy`);
    if(this.selectedCard && this.selectedCard.CardKey)
    this.dashboardService.getPrivacyPolicy(this.selectedCard.CardKey).subscribe(
      res=>{
        this.openModal(res['Content']);
      },
      err=>{
        console.error(err)
      }
    )
  }

  openModal(RedirectUrl){
    const dialogRef = this.dialog.open(PrivacyPolicyComponent, { 
      disableClose : true,
      data : { RedirectUrl : RedirectUrl, DisplayText: true } 
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        window.open(RedirectUrl, "_blanck");
      }
    });
  }

  opensmi(){
    this.analyticsService.gtag(this.componentName, `opensmi`);
    window.open("https://www.smionecard.com/", "_blank");
  }

}
