import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class LoadingScreenService {
  
  isLoading = new BehaviorSubject(false);

  show() { this.isLoading.next(true); }

  hide() { this.isLoading.next(false); }

  forceHide() { this.isLoading.next(false); }

}
