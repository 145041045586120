import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { VerifyComponent } from './verify/verify.component';
import { StepContainerComponent } from './step-container/step-container.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { PasswordStrengthBarComponent } from './password-strength-bar/password-strength-bar.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { PhoneMaskDirective } from './phone-mask/phone-mask.directive';
import { CardExpiryDirective } from './card-expiry-mask/card-expiry-mask.directive';
import { DatePipe } from '@angular/common';

import { ShellModule} from '../../../shell/shell.module';
import { NgxMaskModule } from 'ngx-mask';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { QuickContainerComponent } from './quick-container/quick-container.component';
import { TranslateChildModule } from '@common/translate.module';
import { CommonModule } from '@angular/common';  
import { AlertComponent } from './alert/alert.component';

defineLocale('es', esLocale);
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    ShellModule,
    TranslateChildModule,
    NgxMaskModule.forChild()
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateChildModule,
    StepContainerComponent,
    VerifyComponent,
    PasswordInputComponent,
    PasswordStrengthBarComponent,
    DatepickerComponent,
    PhoneMaskDirective,
    CardExpiryDirective,
    AutofocusDirective,
    NgxMaskModule,
    QuickContainerComponent,
    CommonModule,
    AlertComponent
  ],
  declarations: [
    StepContainerComponent,
    VerifyComponent,
    PasswordInputComponent,
    PasswordStrengthBarComponent,
    DatepickerComponent,
    PhoneMaskDirective,
    CardExpiryDirective,
    AutofocusDirective,
    QuickContainerComponent,
    AlertComponent
  ],
  providers: [
    DatePipe,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class LoginSharedModule { }

