import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Router,
  Route,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { RestService } from './rest.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivateChild(_childRoute: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthorized();
  }
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthorized();
  }

  canLoad(_route: Route): Observable<boolean> {
    return this.isAuthorized();
  }

  private isAuthorized(): Observable<boolean> {
    const toekn: string = this.authenticationService.getToken();
    if (toekn) {
      return of(true);
    } else {
      this.router.navigate([ '/login' ]);
      return of(false);
    }
  }
}


@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private restService: RestService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
      if(this.authenticationService.getToken()) {
          return true;
        }
        this.restService.generateNewIDMLogoutUrl();
      return false;
  }

}
