import { PostLoginResponse, Card } from '@models/post-login.model';
import { ICardActivationResponse } from '@models/card-activation.model';
import { EventWithPayload } from './message-bus.model';
import { IVerifyResponse } from '@models/verify.model';
import { IEnrollmentFlowRequest } from '@models/enrollment-flow.model';
import { ICipWebFlowRequest } from '@models/cip.model';

export class PostLoginResponseEvent extends EventWithPayload<PostLoginResponse> {
  static readonly type = 'PostLoginResponseEvent';
  public readonly type = PostLoginResponseEvent.type;
}

export class SelectedCardEvent extends EventWithPayload<Card> {
  static readonly type = 'SelectedCardEvent';
  public readonly type = SelectedCardEvent.type;
}

export class AlertChangeEvent extends EventWithPayload<string> {
  static readonly type = 'AlertChangeEvent';
  public readonly type = AlertChangeEvent.type;
}

export class InLifeActivateResponseEvent extends EventWithPayload<ICardActivationResponse> {
  static readonly type = 'InLifeActivateResponseEvent';
  public readonly type = InLifeActivateResponseEvent.type;
}

export class VerifyCodeSuccessEvent extends EventWithPayload<IEnrollmentFlowRequest> {
  static readonly type = 'VerifyCodeSuccessEvent';
  public readonly type = VerifyCodeSuccessEvent.type;
}

export class CipWebFlowCodeSuccessEvent extends EventWithPayload<ICipWebFlowRequest> {
  static readonly type = 'CipWebFlowCodeSuccessEvent';
  public readonly type = CipWebFlowCodeSuccessEvent.type;
}

export class VerifyResponseEvent extends EventWithPayload<IVerifyResponse> {
  static readonly type = 'VerifyResponseEvent';
  public readonly type = VerifyResponseEvent.type;
}

export type EventTypes =
    PostLoginResponseEvent |
    SelectedCardEvent |
    InLifeActivateResponseEvent |
    VerifyResponseEvent |
    VerifyCodeSuccessEvent|
    CipWebFlowCodeSuccessEvent|
    AlertChangeEvent;
