import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '@services/rest.service';
// import { AppConfiguration } from '../../../config/app-configuration';

@Injectable({ providedIn: 'root' })
export class SupportService {
    constructor(
        private restService: RestService,
        // private appConfig: AppConfiguration,
    ) { }

    getFolders(FreshDeskCategoryID:string): Observable<any> {        
        let url = `/api/v2/solutions/categories/${FreshDeskCategoryID}/folders`;
        let ret = this.restService.freshDeskUrlGet<any>(url, null, null);       
        return ret;
      }

    getFaqContact(id:string): Observable<any> {
      const httpParams = new HttpParams()      
      .set('per_page', '40')   ;  
      let url = `/api/v2/solutions/folders/${id}/articles`; 
      return  this.restService.freshDeskUrlGet<any>(url, null, httpParams);
    }

    getTicketType(): Observable<any> {    
      const httpParams = new HttpParams()      
      .set('type', 'default_ticket_type')   ;   
      let url = `/api/v2/ticket_fields`; 
      return  this.restService.freshDeskUrlGet<any>(url, null, httpParams);
    }

    public setFeedback(obj: any,cardKey: number ): Observable<any> {    
      let url = `/api/cardholders/${cardKey}/create/ticket`;
      //return this.restService.freshDeskUrlPost<any>('/api/v2/tickets.json', obj); 
      return this.restService.post<any>(url, obj); 
    }

}

