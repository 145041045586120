import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RestService } from '@services/rest.service';
import { AppConfiguration } from '@config/app-configuration';

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {

  constructor(
    private restService: RestService,
    private httpClient: HttpClient,
    private appConfig: AppConfiguration,
  ) { }
  
 
  downloadFile(code): Observable<any> {         
    //return this.restService.get<any>(`/api/cardholders/download/document/${code}`, null, null);
    const header: HttpHeaders = new HttpHeaders()
    .set('isPreLogin','1');
    let url = `${this.appConfig.baseUrl}/api/cardholders/download/document/${code}`;
    return this.httpClient.get<Blob>(url,{
      headers: header,
      responseType:'blob' as 'json'
    });
  }

}
