import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from '@services/rest.service';
import { IdmRestService } from '@services/idmrest.service';
import { AppConfiguration } from '../../../config/app-configuration';

@Injectable({ providedIn: 'root' })
export class SettingsService {

  constructor(
    private restService: RestService,
    private idmRestService: IdmRestService,
    private appConfig: AppConfiguration,
    ) {
  }

  getProfileInfo(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/profile/v2?CardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  updateProfile(cardKey: number, data: any): Observable<any> {    
    return this.restService.post<any>(`/api/cardholders/profile/update?CardKey=${cardKey}`, data); 
  }

  getLanaguages(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/masters/languages?CardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  getTimeZones(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/masters/time-zones?CardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  getCountry(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/masters/countries?CardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  getSecurityQuestion(language:string): Observable<any> {
    let sourceApplicationId = this.appConfig.appId['web']; 
    const httpParams = new HttpParams() 
    .set('language', language)
    .set('sourceApplicationId', sourceApplicationId)   ;       
    let url = "/api/masters/security-questions";
    return this.idmRestService.get<any>(url, null, httpParams);
  }

  setSecurityQuestion(objSecurity): Observable<any> {    
    return this.idmRestService.post<any>('/api/users/security-questions', objSecurity);
  }

  getNotificationSettings(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/notifications?CardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }
  
  getFeeDetails(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/masters/program/fee-details?CardKey=${cardKey}`;
    return this.restService.get<any>(url, null, httpParams);
  }

  getFeeMetaData(cardKey: number): Observable<any> {
    const httpParams = new HttpParams();    
    let url = `/api/cardholders/policy/latest?CardKey=${cardKey}&contentType=FeeLHSMaster`;
    return this.restService.get<any>(url, null, httpParams);
  }
  getPasswordPolicy(language:string): Observable<any> {
    let applicationID = this.appConfig.appId['web']; 
    const httpParams = new HttpParams() 
    .set('applicationID', applicationID)
    .set('isnew', 'false')
    .set('language', language)  ; 
    let url = "/api/application/password-policy";
    return this.idmRestService.get<any>(url, null, httpParams);
  }

  updatePassword(objPassword): Observable<any> {    
    return this.idmRestService.post<any>('/api/users/update-password', objPassword);
  }

  updateNotificationSettings(cardKey: number, request): Observable<any> {    
    return this.restService.post<any>(`/api/cardholders/notifications?CardKey=${cardKey}`, request);
  }
  updateNickname(cardKey: number,objNickname): Observable<any> {    
    return this.restService.post<any>(`/api/cardholders/cards/update-nickname?CardKey=${cardKey}`, objNickname);
  }

}
