import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DownloadAppComponent } from '@common/download-app/download-app.component';
import { HighRiskComponent } from '@common/high-risk/high-risk.component'
import { DownloadFileComponent } from '@common/download-file/download-file.component';
import { ErrorPageComponent } from '@common/error-page/error-page.component';
import { RedirectComponent } from '@common/redirect/redirect.component';
import { SecureRedirectComponent } from '@common/secure-redirect/secure-redirect.component';
import { AuthGuard, LoginGuard } from '@services/auth-guards.service';

import { CardProtectionComponent } from './common/card-protection/card-protection.component';
import { QuickRegistrationComponent } from './pages/login/quick-registration/quick-registration.component';
import { SmiOneShellComponent } from './shell/smione-shell/smione-shell.component';
import { AdditionalVerificationComponent } from './pages/login/additional-verification/additional-verification.component';

export const guards = [
  AuthGuard,
];
const authGuards = {
  canLoad: [ AuthGuard ],
  canActivateChild: [ AuthGuard ],
};


const AppRoutes: Routes = [
  {  path: 'card-protection', component:CardProtectionComponent},
  {  path: 'download-app', component:DownloadAppComponent},
  {  path: 'hrtransactions', component:HighRiskComponent},
  {  path: 'download', component:DownloadFileComponent},
  {  path: 'signupq', component:QuickRegistrationComponent},
  {  path: 'error', component: ErrorPageComponent },
  {  path: 'secure-redirect', component:SecureRedirectComponent},
  {  path: 'additional-verify', component:AdditionalVerificationComponent},
  {
    path: 'login',  
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule),
    canActivate :[ LoginGuard ]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/login/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'pending-login',   
    loadChildren: () => import('./pages/login/login-flow/login-flow.module').then(m => m.LoginFlowModule), ...authGuards
  },
  {
    path: 'trouble-login',
    loadChildren: () => import('./pages/login/trouble-login/trouble-login.module').then(m => m.TroubleLoginModule),
  },
  {
    path: 'enroll',
    loadChildren: () => import('./pages/login/enrollment-flow/enrollment-flow.module').then(m => m.EnrollmentFlowModule),
  },
  {
    path: 'cip',
    loadChildren: () => import('./pages/login/cip-web-flow/cip-web-flow.module').then(m => m.CipWebFlowModule),
  },
  {  path: 'deposit', component:RedirectComponent, canActivate : [ AuthGuard ] },
  {  path: 'fees',    component:RedirectComponent, canActivate : [ AuthGuard ] }, 
  {  path: 'alert',   component:RedirectComponent, canActivate : [ AuthGuard ] },
  {  path: 'setting', component:RedirectComponent, canActivate : [ AuthGuard ] },
  { path: 'smione', component: SmiOneShellComponent, canActivate : [ AuthGuard ], children: [
    { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), ...authGuards },
    { path: 'card', loadChildren: () => import('./pages/dashboard/card/card.module').then(m => m.CardModule) , ...authGuards},
    { path: 'manage', loadChildren: () => import('./pages/dashboard/manage/manage.module').then(m => m.ManageModule), ...authGuards },
    { path: 'savings', loadChildren: () => import('./pages/dashboard/savings/savings.module').then(m => m.SavingsModule), ...authGuards },
    { path: 'support',  loadChildren: () => import('./pages/dashboard/support/support.module').then(m => m.SupportModule), ...authGuards },
    { path: 'settings', loadChildren: () => import('./pages/dashboard/settings/settings.module').then(m => m.SettingsModule), ...authGuards },
    { path: 'payperks',  loadChildren: () => import('./pages/dashboard/pay-perks/pay-perks.module').then(m => m.PayPerksModule), ...authGuards },
    { path: '**', component: ErrorPageComponent },
  ]
},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })
  ], 
  exports: [RouterModule]
})
export class AppRoutingModule { }
