import { ErrorHandler, EventEmitter } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { NewableType, CallbackFunction } from './message-bus.model';
import { EventTypes } from './message-bus-events';
import { Card } from '@models/post-login.model';

@Injectable({
  providedIn: 'root'
})
export class MessageBusService {

  private errorHandler: ErrorHandler;
  private eventStream: ReplaySubject<any>;
  newCard: EventEmitter<Card> = new EventEmitter();
  public cardChange = new BehaviorSubject<Card>(null);

  constructor(errorHandler: ErrorHandler) {
    this.errorHandler = errorHandler;
    this.eventStream = new ReplaySubject();
  }
  emitCard(Card) {
    this.newCard.emit(Card);
    this.emitCardChange(Card);
  }

  emitCardChange = (card) => { this.cardChange.next(card); };

  getCard() {
    return this.newCard;
  }
  public emit(event: EventTypes): void {
    this.eventStream.next(event);
  }

  public on<T>(typeFilter: NewableType<T>,
    callback: CallbackFunction<T>,
    callbackContext: any = null): Subscription {

    const subscription = this.eventStream.pipe(
      filter((event: any): boolean => {
              return(event instanceof typeFilter);
            }
        )
    ).subscribe((event: T): void => {
      try {
        callback.call(callbackContext, event);
      } catch (error) {
        this.errorHandler.handleError(error);
      }
    });
    return(subscription);
  }


  public subscribe(
    callback: CallbackFunction,
    callbackContext: any = null): Subscription {

    const subscription = this.eventStream.subscribe(
      (event: any): void => {
        try {
          callback.call(callbackContext, event);
        } catch (error) {
          this.errorHandler.handleError(error);
        }
      });
    return(subscription);
  }
}
