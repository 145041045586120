<div class="apps-header">
    <div class="container-fluid">
        <div class="row">
            <div class="logo">       
                <img src="./assets/images/logo-main.png" width="" alt="smiONE logo">
            </div>
        </div>
    </div>
</div>
<div>
    sample content
</div>
<footer role="group">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="footer-part footer-r-b">

                    <div class="footer-logo">
                        <h3>
                            <img src="./assets/images/logo-white.png" alt="Footer logo">
                        </h3>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="footer-part">                   
                    <span class="mt-10">
                        {{'DASHBOARD.FOOTER.Copright2020SIMOne' | translate}} <br/> {{'DASHBOARD.FOOTER.AllRightsReserved' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</footer>