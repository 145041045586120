import { Injectable } from '@angular/core';
import { AppConfiguration } from '@config/app-configuration';
declare let gtag: Function;

@Injectable({providedIn:'root'})
export class AnalyticsService {

    constructor(private appConfig: AppConfiguration) { }

    public gtag(eventName, eventLabel ){
        gtag(
            'event', 
            eventName, 
            { 
                userId: localStorage.getItem('userId'), 
                event_label : eventLabel
            });
    }
}
