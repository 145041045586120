import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { RestService } from '@services/rest.service';
import { AppConfiguration } from '@config/app-configuration';

@Injectable({
  providedIn: 'root'
})
export class AdditionalVerificationService {

  constructor(
    private restService: RestService,
    private http: HttpClient,
    private appConfig: AppConfiguration,
  ) { }
  
  verifyAccess(id): Observable<any> {
    return this.http.post<any>(`${this.appConfig.idmServiceUrl}/api/users/reset-password/access?id=${id}`, null);
  }

  getPolicyText(): Observable<any> {         
    const params = new HttpParams()
    .set('applicationID', this.appConfig.appId['web'])
    .set('isnew', 'false')
    .set('language','en')   
    return this.http.get<any>(`${this.appConfig.idmServiceUrl}/api/application/password-policy`, { params : params });
  }

  resetPassword(request): Observable<any> {
    return this.restService.post<any>('/api/cardholders/reset-password/v2', request);
  }

}
