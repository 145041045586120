import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'smione-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  txtFailure :string;
  componentName : string = 'ErrorModal';
  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    if(!this.dialogData.message){
      this.translate.get('DASHBOARD.MYCARD.UnableToProcessTheRequest').subscribe((data) => { 
        this.txtFailure = data; 
      });
    }
  }

  close(data?){
    this.analyticsService.gtag(this.componentName, 'close');
    this.dialogRef.close(data);
  }
 

}
