<div class="modal-content border-0" role="main">
        
    <div class="modal-header border-0 p-0">
        <button (click)="close()" type="button" class="close p-0" data-dismiss="modal" aria-label="btnClose"><i class="fa fa-close"></i></button>
    </div>

    <div class="modal-body web-modal-popup">
        <h6> {{'DASHBOARD.WA.Notification' | translate }} </h6>
        <div class="notify-data-main" *ngFor="let i of dialogData;let ind = index">
            <ng-container *ngIf="ind === count">
                <div class="notify-data">
                    <div class="notify-img">
                        <img src="./assets/images/support-icons/Rectangle-white.png" width="25px" alt = "Support">
                    </div>
                    
                    <span> {{i?.CreatedDateTime | date :'MM/dd/yy' }} </span>
                </div> 
                
                <div class="notify-content">
                    <p [innerHTML]="i?.Content"></p>
                </div>

                <div class="notify-footer">
                    <span> {{count+1}}/{{dialogData?.length}} </span>
                    <div>
                        <button cdkFocusInitial *ngIf="(count+1) === dialogData?.length" (click)="makeAlertRead(i)" type="button" class="green-btn mr-10"> {{'DASHBOARD.WA.OK' | translate }} </button>
                        <button *ngIf="(count+1) !== dialogData?.length" (click)="makeAlertRead(i)" type="button" class="green-btn"> {{'DASHBOARD.WA.Next' | translate }} </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <!-- <pre>{{ dialogData | json }}</pre> -->
    </div>
</div>