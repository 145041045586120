import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'smione-card-protection',
  templateUrl: './card-protection.component.html',
  styleUrls: ['./card-protection.component.scss']
})
export class CardProtectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
