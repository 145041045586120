<div class="apps-header">
    <div class="container-fluid">
        <div class="row">
            <div class="logo">       
                <img src="./assets/images/logo-main.png" width="" alt="smiONE logo">
            </div>
        </div>
    </div>
</div>

<div class="andriod align-center">
    <div class="andriod-text">
        <h2>{{'DASHBOARD.DOWNLOADAPP.Title' | translate}}<sup>TM</sup></h2>
        <h3>{{'DASHBOARD.DOWNLOADAPP.SubTitle1' | translate}}<sup>TM</sup> {{'DASHBOARD.DOWNLOADAPP.SubTitle2' | translate}}
        </h3>
        <div class="app-img">
            <span>
                <a href="https://play.google.com/store/apps/details?id=com.smi.m360.cardholder&hl=en_US" target="_blank" aria-label="Link on Play Store">
                    <img src="./assets/images/play_store.png" alt = "Get it on Play Store">
                </a>
            </span>
            <span>
                <a href="https://itunes.apple.com/us/app/platinum-smione-visa-prepaid/id1147476680?mt=8" target="_blank" aria-label="Link on Google Play">
                    <img src="./assets/images/ios_app_store.png" alt = "Get it on Google Play">
                </a>
            </span>
        </div>
    </div>
</div>