import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ThemeService } from '@services/theme.service';
import { SupportService } from '../../pages/dashboard/support/support.service';
import { PostLoginResponseEvent } from '@services/message-bus/message-bus-events';
import { Card } from '@models/post-login.model';
import { MessageBusService } from '@services/message-bus/message-bus.service';
import { MessageModalComponent } from '@common/message-modal/message-modal.component';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'smione-old-website',
  templateUrl: './old-website.component.html',
  styleUrls: ['./old-website.component.scss']
})
export class OldWebsiteComponent implements OnInit {
  selectedCard: Card;
  feedback:string = "";
  parentCard:Card;
  componentName : string = 'OldWebsite';
  constructor(
    private dialog: MatDialog,
    public messageBus: MessageBusService,
    private supportService: SupportService,    
    public dialogRef: MatDialogRef<OldWebsiteComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private translate: TranslateService,
    private themeService: ThemeService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.selectedCard = this.dialogData.Card;
    this.themeService.SetThemeInitially();  
    this.fetchParentCard();
  } 

  close(){
    this.analyticsService.gtag(this.componentName, 'close');
    this.dialogRef.close();
  }

  SaveFeedBack(){    
    this.analyticsService.gtag(this.componentName, `SaveFeedBack`);
    if(this.feedback.trim()!="")
      this.saveData();
    else
     this.ConfirmPopup();
  }

  saveData()
  {     
    this.feedback = this.feedback.replace(/\n/g, "<br/>");
      var obj=
      {
        "subject": "Website Feedback - Review Request",
        "type": "Website Feedback",
        "priority": 1,
        "status": 2,
        "description": this.feedback  + "  <br>  " +  "2022 Website Redesign URL: " + location.href,
      };         
      this.supportService.setFeedback(obj, this.parentCard.CardKey).subscribe(
        res => { 
          this.ConfirmPopup();
        },       
      );

  }
  fetchParentCard()
  {
     var x = this;
    this.messageBus.on(PostLoginResponseEvent, (event) => {       
      event.payload.Cards.forEach(function(primaryCard, i){ 
        if (x.selectedCard.ParentCardKey === primaryCard.CardKey)
          x.parentCard = primaryCard;

        });
        if (!x.parentCard)
          x.parentCard = x.selectedCard;
    });    
  }

  ConfirmPopup()
  {   
    this.translate.get('DASHBOARD.OLDWEBSITE.Confirmation').subscribe((translatedValue)=>{ 
      const dialogRef = this.dialog.open(MessageModalComponent, { 
        disableClose : true,
        data : { message : translatedValue } 
      })                  
    });
    localStorage.clear();
    sessionStorage.clear();
    this.close();
  }
}
