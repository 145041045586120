import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { AppConfiguration } from '@config/app-configuration';
declare const gtag: Function;
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  constructor(private appConfig: AppConfiguration) {
  }

  public setToken(token: string): void {
    if(token == null)
      localStorage.removeItem('token');
    else
      localStorage.setItem('token', token);
      let decoded = jwt_decode(token);
      console.log('decoded', decoded);
      console.log('userId', decoded['user_id']);
      if(decoded['user_id'] || decoded['user_id'] != ""){
        localStorage.setItem('userId', decoded['user_id']);
        gtag('config', this.appConfig.googleAnalyticsId, { 'userId': decoded['user_id'] });
      }
  }

  public getToken(): string {
    let token = localStorage.getItem('token');
    if(token){
      return token;
    }
    return null;
  }

  public setState(token: string): void {
    localStorage.setItem('state', token);
  }

  public getState(): string {
    let state = localStorage.getItem('state');
    if(state){
      return state;
    }
    return null;
  }

  public removeState(): void {
    localStorage.removeItem('state');
  }

  public setTokenState(token: string): void {
    localStorage.setItem('token_state', token);
  }

  public getTokenState(): string {
    let token_state = localStorage.getItem('token_state');
    if(token_state){
      return token_state;
    }
    return null;
  }

  public removeTokenState(): void {
    localStorage.removeItem('token_state');
  }
  
}
