import { Component, OnInit } from '@angular/core';
import { RestService } from '@services/rest.service';
import { HighRiskService } from './high-risk.service'
import { MessageUtils } from '@utils/message.utils';
import { FormBuilder,FormGroup, Validators, AbstractControl } from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@services/analytics.service';

@Component({
  selector: 'smione-high-risk',
  templateUrl: './high-risk.component.html',
  styleUrls: ['./high-risk.component.scss']
})
export class HighRiskComponent implements OnInit {
  PreferredLanguage:string;
  code:string;
  errorMessage:string ="";
  isCodeValid:boolean = false;
  hrData:any;
  cardNumber:string="";
  codeRes:string ="";
  isError :boolean = false;
  componentName : string = 'HighRisk';
  
  constructor( 
    private restService: RestService,
    private hrService:HighRiskService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService
    ) 
    { 
      this.restService.setDefaultLanguage();
      this.PreferredLanguage = this.parseQueryString('lang');
      if (!this.PreferredLanguage)
        this.PreferredLanguage = "en";
      this.code = this.parseQueryString('code');      
    }

  ngOnInit(): void {
    this.verifyCode();
  }
  parseQueryString(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  verifyCode()
  {
    this.hrService.verifyCode(this.code, this.PreferredLanguage).subscribe(
      (res) =>{  
        if(res)
        {
          this.hrData = res[0];         
          this.isCodeValid = true;          
          this.cardNumber = res[0]["cardLst4Digits"];
          this.codeRes = res[0]["code"];
        }  
        else
        {   
          this.isError = true;        
          let message = MessageUtils.getErrorMessageForSuccessResponse(res);        
          this.errorMessage= message;  
        }  
      },
      error => {   
        this.isError = true; 
        const messages = error.error.Messages;
        if(messages)
        {
          const message = messages.map(d => d.DisplayText).join('.');
          this.errorMessage= message;   
        }        
      }); 
  }

  sendResponse(response:boolean){ 
    this.analyticsService.gtag(this.componentName, 'sendResponse');
    this.isCodeValid = false;   
    var objBody = {
      isValidTransaction:response,
      code:this.codeRes
    };
    this.hrService.identifyRisk(this.PreferredLanguage, objBody).subscribe(
      (res) =>{       
        if(res.ResponseCode === 200 || res.ResponseCode === 2000){  
          this.isError = false;         
        this.errorMessage =  MessageUtils.getErrorMessageForSuccessResponse(res); 
        
        }else{
          this.isError = true;
          this.errorMessage =  MessageUtils.getErrorMessageForSuccessResponse(res); 
        }
      },
      (error)=>{
        this.isError = true;
        const messages = error.error.Messages;
        if (messages) {
          const message = messages.map(d => d.DisplayText).join('.');
          this.errorMessage = message;
        }
        else {
          this.errorMessage = "  ";
          console.error(error);
        }
      },); 
  }

 }
