import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../common/material.module';
import { SimoneErrorComponent } from './smione-shell/simone-error/simone-error.component';
import { NgxHideOnScrollModule } from 'ngx-hide-on-scroll';
import { TranslateChildModule } from '@common/translate.module';
import { SmiOneShellComponent } from './smione-shell/smione-shell.component';
import { SmioneHeaderComponent } from './smione-shell/smione-header/smione-header.component';
import { SmioneFooterComponent } from './smione-shell/smione-footer/smione-footer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SmiOneHttpInterceptor } from '@services/smi-http-interceptor.service';
import { BlobErrorHttpInterceptor } from '@services/blob-error.interceptor.service';
import { guards } from '../app-routing.module';
import { MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
@NgModule({
  declarations: [
    SmiOneShellComponent,
    SmioneHeaderComponent,
    SmioneFooterComponent,
    SimoneErrorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    MatMenuModule,
    NgxHideOnScrollModule,
    TranslateChildModule,
    RouterModule
  ],
  exports: [
    SmiOneShellComponent,
    SmioneHeaderComponent,
    SmioneFooterComponent
  ],
  providers: [
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: SmiOneHttpInterceptor, 
      multi: true 
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true
    },
    {
       provide: MatDialogRef,
       useValue: {}
     },
    ...guards,
  ],
 
})
export class ShellModule {}
