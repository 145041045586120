import { Component, OnInit, NgZone} from '@angular/core';
import { LoadingScreenService } from '@services/loading-screen.service';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RestService } from '@services/rest.service';

@Component({
  selector: 'smione-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: [ './loading-screen.component.scss' ]
})
export class LoadingScreenComponent implements OnInit {

  isLoading: boolean = false;
  isLoadingWorker: Observable<boolean> | undefined;

  constructor(
    private loadingService: LoadingScreenService,
    private zone:NgZone, 
    private isLoadingService: IsLoadingService, 
    private router: Router,
    private restService: RestService,
    ) {
      this.restService.setDefaultLanguage();
      this.loadingService.isLoading.subscribe((v) => {
        this.zone.run(()=>{
            this.isLoading = v;
          });    
        },
        err => console.error(err)
      ); 
  }

  ngOnInit() {
    this.isLoadingWorker = this.isLoadingService.isLoading$();
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
      )
      .subscribe(event => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });
  }

}
