<div>
    <div id="applicationLoadingAuth" style="display: none;">
      <div class="loading-screen-wrapper">
        <div class="loading-screen-icon">
          <div class="blobs">
            <div class="blob">
              <img src="./assets/images/loader-img.gif" alt="Loading">
              <p> LOADING... </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>
  <script>
    document.getElementById('applicationLoadingAuth').style.display = 'block';
    var initialisePage = function () {
      console.log('page is fully loaded.');
      document.getElementById('applicationLoadingAuth').style.display = 'none';
  
    };
    try {
      window.addEventListener("load", initialisePage, false);
    } catch (e) {
      window.onload = initialisePage;
    }
  
  </script>