import {Injectable} from '@angular/core';
import {AppConfiguration} from './app-configuration';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class JsonAppConfigService extends AppConfiguration {
  constructor(private http: HttpClient) {
    super();
  }

  // This function needs to return a promise
  load() {
    return this.http.get<AppConfiguration>('app/config/app.config.json')
      .toPromise()
      .then(data => {
        console.log('config',data);
        this.title = data.title;
        this.baseUrl = data.baseUrl;
        this.appId = data.appId;
        this.idmCoreServiceUrl = data.idmCoreServiceUrl;
        this.idmServiceUrl = data.idmServiceUrl;
        this.usc = data.usc;
        this.redirect_uri = data.redirect_uri;
        this.countryCode = data.countryCode;
        this.freshDeskUrl = data.freshDeskUrl;
        this.logoutTime = data.logoutTime;
        this.serverHost =  data.serverHost;
        this.bus_no = data.bus_no;
        this.poc = data.poc;
        this.theme = data.theme;
        this.show_theme = data.show_theme;
        this.version = data.version; 
        this.websiteurl = data.websiteurl;       
        this.old_website = data.old_website;     
        this.googleAnalyticsId = data.googleAnalyticsId;     
      })
      .catch(() => {
        console.error('Could not load configuration');
      });
  }
}
