import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfiguration } from '@config/app-configuration';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'smione-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
  txtMessage :string = "";
  constructor(
    private appConfig: AppConfiguration,
    public dialogRef: MatDialogRef<MessageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    setTimeout(()=>{
      this.dialogRef.close(); 
      window.location.href = this.appConfig.old_website + "/redirect/website"; 
    },5000);
  }
}
